// @import "../shared/styles/portal/index.scss";
@import './tabs';
@import './shade';
@import './fullcalendar';
// @import "./dropzone";

html,
body {
  width: 100%;
  min-height: 100%;
}
body {
  @media print {
    background-color: #fff;
  }
}
#app {
  min-height: 100%;
}

#main {
  overflow-y: auto;
  position: fixed;
  top: 60px;
  bottom: 60px;
  left: 0;
  right: 0;
}
.comment-body {
  word-break: break-word;
}

.modal-content {
  background-color: #fff;
}
.modal-dialog {
  outline: none;
}
.react-pdf__Document {
  padding-bottom: 10px;
}
.react-pdf__Page {
  padding: 10px 10px 0px 10px;
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  border: 1px solid #e3ebf6;
}
.react-pdf__Page__textContent {
  margin-top: 7px;
}
.text-responsive {
  font-size: calc(100% + 1vw + 1vh);
}
.text-larger {
  font-size: larger;
}
.text-xx-large {
  font-size: xx-large;
}

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-small {
  font-size: 0.8125rem;
}
@media (min-width: 768px) {
  .text-normal-md {
    font-size: 0.9375rem;
  }
}

.main-light {
  background-color: #f9fbfd;
}
.table-xs th,
.table-xs td {
  padding: 0.25rem;
}
.table-bw th {
  // color:#000;
  color: #fff;
  background-color: #15c;
}
.table-bw td {
  background-color: #fff;
}
a.nav-link {
  cursor: pointer;
}

.nav-tabs-custom.card-header-tabs {
  margin-top: 0;
  margin-bottom: 0;
}
.nav-tabs-custom .nav-item {
  margin-right: 0;
}
.nav-tabs-custom .nav-item .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 0.5rem 1rem !important;
}
.nav-tabs-custom .nav-link.active {
  color: #12263f;
  background-color: transparent;
  border-color: #dee2e6 #dee2e6 transparent;
}
.nav-tabs-custom .nav-link:hover {
  // color: #12263F;
  // background-color: transparent;
  border-color: #dee2e6 #dee2e6 transparent;
}
.table-highlight {
  background-color: #f9fbfd;
  color: #15c;
  font-weight: bold;
}
.td-sticky {
  position: sticky;
  top: 0;
  background-color: white;
}
.table-tight th,
.table-tight td {
  padding-top: 0.46875rem;
  padding-bottom: 0.46875rem;
}
.table-plain {
  margin-bottom: 0;
}
.table-plain thead th {
  color: unset;
  background-color: unset;
  text-transform: unset;
  letter-spacing: unset;
}
.table-plain th,
.table-plain td {
  padding: 0.125rem;
}

/*********************************
 * PROPSOAL SPECIFIC
 *********************************/
// .proposal-wrapper {}
.proposal-container {
  font-size: larger;
}

@media only print {
  html {
    font-size: 120%;
  }
  html,
  body {
    min-height: unset;
  }
  body > :last-child {
    margin-bottom: -1px;
    overflow: hidden;
  }
  .proposal-container {
    font-size: medium;
  }
}
@media print {
  body .row {
    width: 100%;
    .container {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    }

    .no-wrap {
      flex-wrap: nowrap;
    }

    .col-12 {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      padding: 0 15px;
    }

    .col-md-4 {
      flex: 0 0 auto;
      width: 45.3333%;
      max-width: 45.3333%;
    }

    .col-md-8 {
      flex: 0 0 auto;
      width: 54.6667%;
      max-width: 54.6667%;
    }
  }
  #your-proposal {
    .my-5 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

section.page,
section.page:hover {
  display: block;
  position: relative;
  page-break-after: page;
  background-color: white;
  border: 1px solid #e3ebf6;
  float: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  &:last-child {
    page-break-after: auto;
    margin-bottom: -2px;
  }
  @media screen {
    max-width: 8.5in;

    padding-bottom: 2.25rem;
    padding-top: 2.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  @media screen and (min-width: 768px) {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  // @media print {
  //   max-width: unset;
  //   width: 100vw;
  //   height: 100vh;
  //   border: none;
  //   padding-top: 0;
  //   padding-right: 0.5in;
  //   padding-bottom: 0;
  //   padding-left: 0.5in;
  // }
  .page-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .col-block {
    padding: 0.5rem 1rem;
    border-left: 1px solid var(--tenant-primary-color, #15c);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .header {
    break-before: page;
  }
}
.table-savings {
  @media only screen {
    min-width: 650px;
  }
  @media only print {
    font-size: smaller;
    tr th {
      width: 30%;
    }
  }
}
@media only print {
  .table-savings {
    font-size: smaller;
    tr th {
      width: 30%;
    }
  }
  .table-savings > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
  }
  .my-print-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  #forecasted-value .card {
    h1,
    h2,
    h3,
    h4 {
      font-size: small;
    }
  }
}
// @media print {
//   #our-technology {
//     .col-12 {
//       width: unset;
//       flex: unset;
//     }
//   }
// }
.card-fill {
  height: calc(100% - 1.5rem);
}
.list-group-activity .list-group-item:not(:last-child)::before {
  content: '';
  position: absolute;
  top: 1rem;
  left: 1.25rem;
  height: 100%;
  border-left: 1px solid #e3ebf6;
}
.list-group-activity .list-group-item {
  border: 0;
}
.design-svg {
  max-height: 6in;
  width: fit-content;
}
.modal-overlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  overflow-y: auto;
  max-height: 100vh;
}
.emoji-icon-col {
  width: 60px;
  text-align: center;
}
.admin-sidebar {
  width: 400px;
  min-width: 400px;
  position: relative;
  .admin-inner {
    background-color: #fffacd;
    // position:fixed;
    // width: 400px;
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-y: auto;
  }
}
.custom-range,
.form-range {
  border: none;
}
.input-number {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.padding {
  padding: 10px;
}

.checkmark {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
}

.checkmark::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 12px;
  border-right: 3px solid green;
  border-bottom: 3px solid green;
  transform: rotate(45deg);
  left: 9px;
  top: 4px;
}

.cross {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
}

.cross::before,
.cross::after {
  content: '';
  position: absolute;
  width: 3px;
  height: 24px;
  background-color: red;
  top: 0;
  left: 50%;
  transform-origin: center;
}

.cross::before {
  transform: rotate(45deg);
}

.cross::after {
  transform: rotate(-45deg);
}

.triangle {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 24px solid orange;
  position: relative;
  top: -30px;
}

.triangle::before {
  content: '!';
  position: absolute;
  left: 50%;
  top: 105%;
  transform: translateX(-50%);
  font-size: 18px;
  font-weight: bold;
  color: black;
}

.form-row.form-row-slim > .col,
.form-row.form-row-slim > [class*='col-'] {
  padding-right: 1px;
  padding-left: 1px;
}

// PAGE SEPARATOR
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 8.5in;
  margin-left: auto;
  margin-right: auto;
}
.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #e3ebf6;
}
.separator:not(:empty)::before {
  margin-right: 1rem;
}
.separator:not(:empty)::after {
  margin-left: 1rem;
}

.table th.border-top-summary,
.table td.border-top-summary {
  border-top-width: 4px;
  border-top-color: #12263f;
}

#savings-chart {
  --color-1: rgb(177, 194, 217);
  --color-2: rgb(17, 85, 204);
}

.charts-css,
.charts-css::after,
.charts-css::before,
.charts-css *,
.charts-css *::after,
.charts-css *::before {
  box-sizing: border-box;
}
.charts-css {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  border: 0;
}
.charts-css:not(.legend-inline) {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.charts-css.legend {
  padding: 1rem;
  border: 1px solid var(--legend-border-color);
  list-style: none;
  font-size: 1rem;
}
ul.charts-css,
ol.charts-css {
  list-style-type: none;
}
.charts-css.legend li {
  line-height: 2;
  display: flex;
  align-items: center;
}
ul.charts-css li,
ol.charts-css li {
  margin: 0;
  padding: 0;
  border: 0;
}
.charts-css.legend li::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-inline-end: 0.5rem;
  border-width: 2px;
  border-style: solid;
}
.charts-css.legend-square li::before {
  border-width: 0;
  width: 1rem;
  height: 1rem;
  border-radius: 3px;
}
.charts-css.legend li:nth-child(1)::before {
  background-color: var(--color-1, transparent);
  border-color: var(--border-color-1, var(--border-color, #000));
}
.charts-css.legend li:nth-child(2)::before {
  background-color: var(--color-2, transparent);
  border-color: var(--border-color-2, var(--border-color, #000));
}

.charts-css.legend-square li::before {
  border-width: 0;
}
.border-x-white {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}
.modal-header-tabs .nav-link {
  padding-top: calc((1.75rem - 1em * 1.5) / 2) !important;
  padding-bottom: calc((1.75rem - 1em * 1.5) / 2) !important;
}
.savings-data-column {
  width: 250px;
  @media print {
    width: 275px;
  }
}

.popover-body {
  color: unset;
}
.btn-inline {
  text-align: left;
  line-height: 1.3em;
}

.list-links {
  .list-inline-item + .list-inline-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: '•';
  }
}

.info-icon {
  margin-top: -0.2rem;
  margin-left: 0.7rem;
}

.youtube-container {
  display: flex;
  padding: 10px;
  justify-content: center;
}

.trix-content {
  figure {
    margin: 0;
  }
  figure > img {
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
    margin-bottom: 0;
  }
}

.link {
  cursor: pointer;
  &:hover {
    color: #0b3885;
    text-decoration: none;
  }
}
.bg-light-primary {
  background-color: #c9daf8;
}

// RECOMMENDATIONS
.recommendations {
  .form-control-sm {
    height: calc(1.25em + 0.25rem + 2px);
    padding: 0.125rem 0rem;
    line-height: 1.25;
  }
  .col-form-label-sm {
    line-height: 1.25;
  }
}
.bg-danger-soft {
  background-color: #fad7dd !important;
}

// PACKET
.blockquote {
  border-left: 3px solid #15c;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.instrument-container:hover {
  background: #dcebf6;
}

.average-modal-size {
  width: 600px;
  padding: 15px;
}

.div-item-select {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.div-item-select:hover {
  background-color: #f8fafc;
}

.div-item-selected {
  background-color: #f8fafc;
}

.apply-padding {
  padding: 15px;
}

.hide {
  visibility: hidden;
}

.triangle-down {
  position: relative;
  top: -5px;
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 0.1em solid black;
  border-top: 0.1em solid black;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1em;
}
.triangle-up {
  position: relative;
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 0.1em solid black;
  border-top: 0.1em solid black;
  transform: rotate(-45deg);
  margin-right: 0.5em;
  margin-left: 1em;
}

.general-modal-error {
  font-size: 12px;
  color: red;
  position: absolute;
  right: 495px;
}

.modal-xl {
  width: 100%;
}

.error-block {
  background: rgb(203, 133, 133);
  border: 1px solid rgb(201, 47, 47);
  padding: 20px;
}

.alert-link {
  cursor: pointer;
}

.month-container {
  display: flex;
  justify-content: space-between;
}

.month-value {
  width: 50px;
  font-size: 12px;
  input {
    text-align: center;
    height: 40px;
    width: 100%;
    border: 1px solid #d2ddec;
  }
}

.error-block-2 {
  background: rgb(255 187 156);
  padding: 10px;
  border-top: 2px solid #f78282;
  border-bottom: 2px solid #f78282;
}

.success-block {
  background: rgb(156, 255, 158);
  padding: 10px;
  border-top: 2px solid #6ae0ab;
  border-bottom: 2px solid #6ae0ab;
}

.grid-container-col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  width: 500px;
}

.grid-item {
  padding: 10px;
}

.align-center {
  display: flex;
  justify-content: center;
}

.recommendation-filters {
  width: 350px;
  padding: 25px;
  border-right: 1px solid lightgrey;
  height: 100vh;
  overflow-y: scroll;
  transition: width 0.5s;

  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(175, 197, 227);
    border-radius: 0px;
  }
}

.recommendation-view {
  display: flex;
}

.recommendation-container {
  padding: 25px;
  width: 95%;
  margin: auto;
}

.last-input-class {
  height: 100px;
}

.recommendation-header {
  border-bottom: 1px solid #c3c9d4;
}

.recommendation-header > img {
  width: 200px;
  height: 20%;
  padding-bottom: 5px;
}

.recommendation-body {
  padding-top: 25px;
}

.filter-toggle-btn {
  width: 35px;
  height: 35px;
  background: #dadfff;
  border: none;
  border-radius: 50%;
  position: relative;
  top: 45%;
  left: -19px;
}

.ReactVirtualized__Table__headerRow {
  background: rgb(53 76 236 / 71%);
  font-size: 12px;
  color: white;
  font-weight: 500;
}

.ReactVirtualized__Table__row {
  border-bottom: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
}

.ReactVirtualized__Table__rowColumn {
  padding-left: 20px;
}

.ReactVirtualized__Table__headerTruncatedText {
  padding-left: 20px;
}

.ReactVirtualized__Table__sortableHeaderColumn {
  display: flex;
}

.ReactVirtualized__Table__sortableHeaderIcon {
  width: 1.3em !important;
  height: 1.3em !important;
}
.divider-line {
  border-bottom: 1px solid #c9c7c7;
  margin-bottom: 25px;
  padding-bottom: 5px;
}

.divider-label {
  font-size: 14px;
  color: #959191;
}

.recommendation-filters {
  font-weight: 100;
}

.center-align-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-tabs__tab-list {
  font-size: 12px;
}

.disclosure-container {
  font-size: 12px;
  max-height: 15rem;
  overflow: scroll;
}

.print-empty-div {
  display: none;
}
@media print {
  .page {
    border: none !important;
  }
  .print-borderless {
    border: none !important;
  }
  .hide-on-print {
    display: none !important;
  }
  .container {
    max-width: 120%;
  }
  .page-inner {
    padding: 0;
    margin: 0;
  }
  .text-small {
    font-size: 12px;
  }
  .text-center {
    text-align: left;
  }
  .print-row {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .print-row-centered {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    text-align: center; /* Center text within child elements */
    width: 100%;
  }
  .print-row-centered .print-centered-child {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center child elements within this column */
    text-align: center; /* Center text within this column */
    width: 50%;
    h2 {
      width: 100%;
    }
  }
  .print-image {
    flex: 0 0 50%;
  }
  .print-empty-div {
    display: flex;
    flex: 0 0 35%;
  }
  .print-text-block {
    flex: 1;
    text-align: right;
    margin-left: auto;
    padding-left: 100px;
  }
  .print-header-padding {
    padding-left: 50px;
    padding-right: 50px;
  }
  .print-header-padding-left {
    padding-left: 50px;
  }
  .print-header-padding-right {
    padding-right: 50px;
  }
  .print-no-wrap {
    white-space: nowrap; /* Prevent text from wrapping */
  }
}

.collapsible {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.collapsible-header {
  padding: 10px;
  cursor: pointer;
}

.collapsible-content {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 0;
}

.collapsible-content.open {
  max-height: 1000px; /* Adjust as needed */
}

.recs-table {
  position: relative;
  border-collapse: separate;
  border-spacing: 0;
  thead {
    th {
      color: #000;
      position: sticky;
      // box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
      border-bottom: 1px solid var(--bs-table-border-color);
    }
    tr:first-child {
      th {
        top: 0;
      }
    }
    tr:nth-child(2) {
      th {
        top: 24px;
      }
    }
  }
}

td > .form-group {
  margin-bottom: 0;
}

@import 'react-datepicker/dist/react-datepicker.css';
@import '@szhsin/react-menu/dist/index.css';
@import '@szhsin/react-menu/dist/transitions/slide.css';

.react-datepicker {
  border: none;
}
.react-datepicker__header {
  background-color: transparent;
  border-bottom:none;
  padding: 2px 0;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 2rem;
  line-height: 2rem;
  margin: 0.25rem;
}

.col-seventh {
  flex: 0 0 14.285714285%;
  max-width: 14.285714285%;
}
.calendar-header {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  font-size: 16px;
  color: var(--text-color, #1A1A1A);
}
.calendar-label {
  font-size: 16px;
  font-weight: normal;
}
.calendar-row {
  margin-top:8px;
}
.calendar-dow {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
}
.calendar-btn {
  position: relative;
  display: block;
  width: 44px;
  height: 44px;
  margin-right: auto;
  margin-left: auto;
  padding: 1px 0 0;
  color: var(--text-color-level2, rgba(26, 26, 26, 0.6));
  font-size: 16px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 50%;

  background-color: rgba(0, 105, 255, 0.065);
  color: #0060e6;
  font-weight: bold;
}
.calendar-btn:hover {
  background-color: rgba(0, 105, 255, 0.15);
}
.calendar-btn[disabled] {
  color: rgba(26, 26, 26, 0.6);
  font-weight: normal;
  background-color: transparent;

  // visibility: hidden;
  pointer-events: none;
  cursor: default;
}
.calendar-btn[disabled]:hover {
  background-color: transparent;
}
.calendar-btn.selected {
  color: #fff;
  background-color: #0060e6;
  font-weight: bold;
  pointer-events: none;
  cursor: default;
}
.calender-arrow {
  position: relative;
  display: block;
  width: 44px;
  height: 44px;
  margin-right: auto;
  margin-left: auto;
  padding: 1px 0 0;
  color: rgba(26, 26, 26, 0.6);
  text-align: center;
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: transparent;
}
.calender-arrow:hover {
  background-color: rgba(0, 105, 255, 0.065);
}
.calendar-event {
  font-size: 16px;
  margin-bottom: 10px;
  box-sizing: border-box;
  
  white-space: nowrap;
  overflow-x: hidden;
}
.calendar-event-btn {
  background-color: transparent;
  position: relative;
  padding: 13px 10px;
  font-weight: bold;
  vertical-align: top;
  border-radius: 4px;
  transition: all 0.3s ease;
  transition-property: width, transform;
  
  width: 100%;
  height: 52px;
  color: rgb(0, 105, 255);
  border: 1px solid rgba(0, 105, 255, 0.5);
}
.calendar-event-btn:hover ,
.calendar-event-btn:focus {
  border-color: rgb(0, 105, 255);
  border-width: 2px;
  padding: 1px;
}
.calendar-event-btn.selected {
  color: white;
  width: 48.5%;
  background-color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  pointer-events: none;
  cursor: default;
}
.calendar-confirm-btn {
  border: none;
  position: relative;
  padding: 13px 10px;
  font-weight: bold;
  vertical-align: top;
  border-radius: 4px;
  transition: all 0.3s ease;
  transition-property: width, transform;
  
  width: 48.5%;
  height: 52px;
  margin-left: 3%;
  color: white;
  background-color:  rgb(0, 105, 255);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);

  transform: translateX(40px);
}
.calendar-confirm-btn.selected {
  transform: translateX(0);
}
.calendar-confirm-btn:hover {
  background-color: rgba(0, 105, 255, 0.5);
}
.calendar-add-btn {
  font-weight: 600;
  background: #fff;
  font-size: 15px;
  text-decoration: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  border-radius: 3px;
  outline-color: rgba(0, 78, 255, 0.5);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0%);
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0.5px rgba(50,50,93,.17), 0 2px 5px 0 rgba(50,50,93,.1), 0 1px 1.5px 0 rgba(0,0,0,.07), 0 1px 2px 0 rgba(0,0,0,.08), 0 0 0 0 transparent!important;
  &:hover {
    background-color: #fafafa;
  }
}

.shade-report {
  .table-sm th, .table-sm td {
    padding: 0.375rem;
  }
  .table thead th {
    text-transform: none;
    border-bottom: 2px solid #000;
    background-color: transparent;
  }
}
